import {createApp} from "vue";
import "@vant/touch-emulator";

import {
    ActionBar,
    ActionBarButton,
    ActionBarIcon,
    AddressEdit,
    AddressList,
    Badge,
    Button,
    Card,
    Cascader,
    Cell,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    ContactCard,
    Dialog,
    Divider,
    Field,
    Form,
    Grid,
    GridItem,
    Icon,
    Image as VanImage,
    List,
    Loading,
    Overlay,
    Popup,
    PullRefresh,
    Radio,
    RadioGroup,
    Skeleton,
    Stepper,
    SubmitBar,
    Swipe,
    SwipeCell,
    SwipeItem,
    Tab,
    Tabbar,
    TabbarItem,
    Tabs,
    Tag,
    Toast,
    Uploader,
} from "vant";

import App from "./App.vue";
import store from "./store";
import router from "./router";
import "lib-flexible/flexible";
import "vant/lib/index.css"; // 全局引入样式

const app = createApp(App); // 创建实例


// 全局过滤器
app.config.globalProperties.$filters = {
    prefix(url) {
        if (url && url.startsWith('http')) {
            return url
        } else {
            url = `https://vir-api.343134.xyz${url}`
            return url
        }
    },
    formatDate(val) {
        const date = new Date(Number(val)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        const Y = date.getFullYear() + "-";
        const M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
        const D = date.getDate() + " ";
        const h = date.getHours() + ":";
        const m = date.getMinutes() + ":";
        const s = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds());
        return Y + M + D + h + m + s;
    }

}

app.use(ActionBarButton)
    .use(ActionBarIcon)
    .use(ActionBar)
    .use(Divider)
    .use(Radio)
    .use(RadioGroup)
    .use(Popup)
    .use(Overlay)
    .use(Loading)
    .use(Dialog)
    .use(Toast)
    .use(ContactCard)
    .use(Form)
    .use(AddressEdit)
    .use(AddressList)
    .use(Field)
    .use(CellGroup)
    .use(Cell)
    .use(SwipeCell)
    .use(Icon)
    .use(Stepper)
    .use(Card)
    .use(Button)
    .use(Swipe)
    .use(SwipeItem)
    .use(PullRefresh)
    .use(List)
    .use(Tab)
    .use(Tabs)
    .use(SubmitBar)
    .use(Checkbox)
    .use(VanImage)
    .use(CheckboxGroup)
    .use(Skeleton)
    .use(Uploader)
    .use(Tag)
    .use(Grid)
    .use(GridItem)
    .use(Cascader)
    .use(Tabbar)
    .use(Badge)
    .use(TabbarItem)

app.use(router)
app.use(store)

app.mount('#app')
