import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
//  base:'/mall/',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/PointHome.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/shop',
      name: 'shop',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/infoList',
      name: 'infoList',
      component: () => import(/* webpackChunkName: "login" */ '@/views/InfoList.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/complaint',
      name: 'complaint',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Complaint.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/hot',
      name: 'hot',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Hot.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/info',
      name: 'info',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Info.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/learn',
      name: 'learn',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Learn.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/about',
      name: 'about',
      // component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/task',
      name: 'task',
      component: () => import(/* webpackChunkName: "task" */ '@/views/Task.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/category',
      name: 'category',
      component: () => import(/* webpackChunkName: "category" */ '@/views/Category.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/product-list',
      name: 'product-list',
      component: () => import(/* webpackChunkName: "product-list" */ '@/views/ProductList.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/product/:id',
      name: 'product',
      component: () => import(/* webpackChunkName: "product" */ '@/views/ProductDetail.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import(/* webpackChunkName: "cart" */ '@/views/Cart.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/create-order',
      name: 'create-order',
      component: () => import(/* webpackChunkName: "create-order" */ '@/views/CreateOrder.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/order',
      name: 'order',
      component: () => import(/* webpackChunkName: "order" */ '@/views/Order.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/order-detail',
      name: 'order-detail',
      component: () => import(/* webpackChunkName: "order-detail" */ '@/views/OrderDetail.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/user',
      name: 'user',
      component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue'),
      meta: {
        index: 1
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component: () => import(/* webpackChunkName: "setting" */ '@/views/Setting.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/address',
      name: 'address',
      component: () => import(/* webpackChunkName: "address" */ '@/views/Address.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/point',
      name: 'point',
      component: () => import(/* webpackChunkName: "address" */ '@/views/Point.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/address-edit',
      name: 'address-edit',
      component: () => import(/* webpackChunkName: "address-edit" */ '@/views/AddressEdit.vue'),
      meta: {
        index: 3
      }
    },
    {
      path: '/task-add',
      name: 'task-add',
      component: () => import(/* webpackChunkName: "task-add" */ '@/views/TaskAdd.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/task-publish',
      name: 'task-publish',
      component: () => import(/* webpackChunkName: "task-add" */ '@/views/TaskPublish.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/my-publish',
      name: 'my-publish',
      component: () => import(/* webpackChunkName: "task-add" */ '@/views/MyPublish.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/my-pick',
      name: 'my-pick',
      component: () => import(/* webpackChunkName: "task-add" */ '@/views/MyPick.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/my-assign',
      name: 'my-assign',
      component: () => import(/* webpackChunkName: "task-add" */ '@/views/MyAssign.vue'),
      meta: {
        index: 2
      }
    },
    {
      path: '/my-task',
      name: 'my-task',
      component: () => import(/* webpackChunkName: "my-task" */ '@/views/MyTask.vue'),
      meta: {
        index: 2
      }
    },
  ]
})

export default router
